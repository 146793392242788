#actions > div {
    margin-right: 150px;
  }
  
  @media only screen and (max-width: 580px) {
    #actions > div {
      margin-right: 0px;
    }
  }
  
  #actions {
    margin-bottom: 5px;
    display: table;
    background-color: #eff0f1;
    padding: 10px;
    border-radius: 7px;
    margin: 10px;
    overflow: hidden;
  }
  
  #actions button {
    float: left;
    margin: 2px;
    white-space: nowrap;
  }
  
  .log-container {
    position: relative;
  }
  
  .log-container::before {
    content: 'event log';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    color: #80808075;
  }
  
  .log {
    background-color: #eff0f1;
    margin: 10px;
    color: #00000080;
    border-radius: 7px;
    max-height: 100px;
    overflow: auto;
  }
  
  .log > pre {
    padding: 10px;
    margin: 0px;
  }
  
  .clear-log {
    position: absolute;
    right: 30px;
    top: 5px;
    cursor: pointer;
  }