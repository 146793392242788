.pie_chart_legend .legend-item {
    font-size: 0.9rem;
  }
  
  .pie_chart_legend .legend-item .legend-label {
    color: initial;
  }
  
  .pie_chart_legend .legend-item .legend-value {
    font-size: 1.2rem;
  }
  
  .pie_chart_legend .legend-item .legend-marker {
    width: 1rem;
    height: 1rem;
    border-radius: 0.75rem;
    margin-right: 0.75rem;
  }
  