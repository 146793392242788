ul.nav.nav-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
}
.nav-tabs .nav-link {
  background-color: #f1f1f1 !important;
  color: rgb(171, 174, 177) !important;
border-radius: 0!important;
padding-left: 2rem!important;
padding-right: 2rem!important;
padding-top: 4px!important;
padding-bottom: 4px!important;
 border: 0;
}

.nav-tabs .nav-link.active {
  border-bottom: 0 !important;
  background-color: #8a1351 !important;
  color: white !important;
  border-radius: 0!important;
padding-left: 2rem!important;
padding-right: 2rem!important;
padding-top: 4px!important;
padding-bottom: 4px!important;
}