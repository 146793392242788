.ant-slider-track {
    position: absolute;
    height: 4px;
    background-color: #7d76d3;
    border-radius: 2px;
    transition: background-color 0.3s;
}

.ant-slider-mark-text {
    bottom: 1%;
    position: absolute;
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    word-break: keep-all;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 2rem;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}
.ant-slider-mark-text-active {
    color: #5c6365;
    font-weight: bold;
}

.ant-slider-handle {
    position: absolute;
    width: 2rem;
    height: 2rem;
    margin-top: -1rem;
    background-color: #fff;
    border: solid 2px #c9c9c9;
    border-radius: 50%;
    box-shadow: 0;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}