@import "~bootstrap/scss/bootstrap";
@import "./themes/mixins";
@import "./themes/variables";

@include font-face(
  "HelveticaNeueBold",
  "../../assets/fonts/HelveticaNeue/helvetica-neue-bold"
);
@include font-face(
  "HelveticaNeueMedium",
  "../../assets/fonts/HelveticaNeue/helvetica-neue-medium"
);
@include font-face(
  "HelveticaNeueLight",
  "../../assets/fonts/HelveticaNeue/helvetica-neue-light"
);
@include font-face(
  "HelveticaNeueRegular",
  "../../assets/fonts/HelveticaNeue/helvetica-neue-regular"
);



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
