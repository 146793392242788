@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($path+".eot");
    src: url($path+".eot?#iefix") format("embedded-opentype"), url($path+".woff")
        format("woff"), url($path+".ttf") format("truetype"), url($path+".svg##{$font-name}")
        format("svg");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin textShorten() {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

@mixin prefix($property, $value, $vendors: webkit moz ms o, $default: true) {
  @if $vendors {
    @each $vendor in $vendors {
      #{"-" + $vendor + "-" + $property}: #{$value};
    }
  }
  @if $default {
    #{$property}: #{$value};
  }
}

@mixin screen($size) {
  $desktop: "(min-width: 1024px)";
  $tablet: "(min-width: 768px) and (max-width: 1023px)";
  $mobile: "(max-width: 767px)";
  @if $size == desktop {
    @media only screen and #{$desktop} {
      @content;
    }
  }
  @else if $size == tablet {
    @media only screen and #{$tablet} {
      @content;
    }
  }
  @else if $size == mobile {
    @media only screen and #{$mobile} {
      @content;
    }
  }
  @else {
    @media only screen and #{$size} {
      @content;
    }
  }
}

@mixin flexbox($direction: row, $alignItems: center, $justifyContent: center){
  display: flex;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin circle($bgColor: #69d3e0){
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: $bgColor;
}

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin bordered($width, $color){
  border: $width solid $color;
}

@mixin buttonStyle($color, $background, $border: none) {
  background-color: $background;
  border: $border;
  color: $color;
  & a {
    color: $color;
  }
  & a:hover {
    color: $color;
    text-decoration: none;
  }
}

@mixin boxShadow($boxShadow) {
  -moz-box-shadow: $boxShadow;
  -webkit-box-shadow: $boxShadow;
  box-shadow: $boxShadow;
}

@mixin font-size($font-size, $line-height: normal, $letter-spacing: normal) {
  font-size: $font-size;
  @if $line-height==normal {
    line-height: normal;
  } @else {
    line-height: $line-height / $font-size;
  }

  @if $letter-spacing==normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: #{$letter-spacing / $font-size}em;
  }
}
@mixin headerFont($size: 16px, $weight: normal, $style: normal, $line-height: normal, $letter-spacing: normal) {
  font: {
    family: "LexiaBold";
    style: $style;
    weight: $weight;
  }
  @include font-size($size, $line-height, $letter-spacing);
}

@mixin boldFont($size: 16px, $weight: normal, $style: normal, $line-height: normal, $letter-spacing: normal) {
  font: {
    family: "HelveticaNeueBold";
    style: $style;
    weight: $weight;
  }
  @include font-size($size, $line-height, $letter-spacing);
}

@mixin lightFont($size: 16px, $weight: normal, $style: normal, $line-height: normal, $letter-spacing: normal) {
  font: {
    family: "HelveticaNeueLight";
    style: $style;
    weight: $weight;
  }
  @include font-size($size, $line-height, $letter-spacing);
}

@mixin regularFont($size: 16px, $weight: normal, $style: normal, $line-height: normal, $letter-spacing: normal) {
  font: {
    family: "HelveticaNeueRegular";
    weight: $weight;
    style: $style;
  }
  @include font-size($size, $line-height, $letter-spacing);
}

@mixin mediumFont($size: 16px, $weight: normal, $style: normal, $line-height: normal, $letter-spacing: normal) {
  font: {
    family: "HelveticaNeueMedium";
    weight: $weight;
    style: $style;
  }
  @include font-size($size, $line-height, $letter-spacing);
}

@mixin font($size: 16px, $weight: normal, $style: normal, $family: "HelveticaNeueRegular", $line-height: normal, $letter-spacing: normal) {
  font: {
    family: $family;
    weight: $weight;
    style: $style;
  }
  @include font-size($size, $line-height, $letter-spacing);
}

@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
      #{$o}: nth($args, $i + 1);
    }
  }
  position: $position;
}

@mixin sticky($args, $zindex: 12) {
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
      #{$o}: nth($args, $i + 1);
    }
  }
  -webkit-position: sticky;
  -moz-position: sticky;
  -ms-position: sticky;
   -o-position: sticky;
      position: sticky;
      z-index: 12;
}

// Positioning helpers
@mixin absolute($args: '') {
  @include position(absolute, $args);
}

@mixin fixed($args: '') {
  @include position(fixed, $args);
}

@mixin relative($args: '') {
  @include position(relative, $args);
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); //IE8
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin fade($type) {
  @if $type== "hide" {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 1s;
  } @else if $type== "show" {
    visibility: visible;
    opacity: 1;
    transition: visibility 1s, opacity 1s;
  }
}

@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

@mixin skew-background-transition($initial, $hover, $inverted: false) {
  background: linear-gradient(
    90deg,
    $hover 0%,
    $hover 50%,
    $initial 50%,
    $initial 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;

  background-position: right bottom;
  @if $inverted {
    background-position: left bottom;
  }
  transition: background-position 0.25s ease-out;

  &:hover {
    background-position: left bottom;
    @if $inverted {
      background-position: right bottom;
    }
  }
}

@mixin arrow($direction: down, $size: 5px, $color: #555) {
   width: 0;
   height: 0;
   @if ($direction == left) {
      border-top: $size solid transparent;
      border-bottom: $size solid transparent;
      border-right: $size solid $color;
   }
   @else if ($direction == right) {
      border-top: $size solid transparent;
      border-bottom: $size solid transparent;
      border-left: $size solid $color;
   }
   @else if ($direction == down) {
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-top: $size solid $color;
   }
   @else {
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-bottom: $size solid $color;
   }
}

@mixin hideoutline(){
  outline: none;
  outline-offset: 0px;
}

@mixin textConfig($color, $align: left){
  color: $color;
  text-align: $align;
}
