.ant-table-thead> tr >th{
    color: white;
    background: #8e1457 !important;
    padding:14px;
  }
  .flex-ctn, .filter-table {
   
    margin: 0px auto;
  }
  
  .flex-ctn{
    margin-top:20px;
    margin-bottom:0px;
  }
  .flex-ctn p{margin-bottom: 10px; text-align: left;
     color: #212529;
     
  }
  .fliter-ctn, .serch-ctn, .showtop {margin-top: 30px; }
  .fliter-ctn from > .row > div {
    padding-right: 10px;
    padding-left: 0px;
  }
  .serch-ctn{width: 100%;}
  .showtop{

  }
  .fliter-ctn{ position:relative}
  .ant-checkbox-group {
   
    background-color: black;
    color: #fff;
  }
  .drop-img{width:100%; height: auto;}
  .fliter-ctn .drop-btn {
    max-width: 50px;
    height: auto;
    border: 0px;
    padding: 0px;
    margin: 4px 10px;
  }
  .serch-ctn input{
    margin-top: 5px;
  }
  .ant-input:focus, .ant-input-focused {
    border-color: #70757a33;
    box-shadow: 0 0 0 2px #70757a33;
    border-right-width: 1px;
    outline: 0;
}
.ant-input:hover {
  border-color: #767676;
  border-right-width: 1px;
}
.ant-input{border-color:#767676; border-radius: 8px;}
.form-select:focus {
  border-color: #767676 !important;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgb(112 117 122 / 25%) !important;
}
.ant-picker-range .ant-picker-active-bar {
  
  background: #8e1457;
}
.ant-picker-focused {
  
  border-color: #70757a33;
  box-shadow: 0 0 0 2px rgb(112 117 122 / 20%);
}
.ant-picker:hover, .ant-picker-focused {
  border-color: #767676;
}
.ant-picker  .anticon {
  vertical-align: 1em;
}

.calendar-ctn img{ width: 100%; max-width: 32px;}
.calendar-ctn{    display: inline-block;
  width: 25%;
  margin-left: 0px;
  float: left; }
  .ant-table-thead > tr > th
  {border-right:0px !important;}
  .flex-ctn .calendar-picker{float: left;}
.calendar-picker{display: inline;}
  .ant-checkbox-group:after {
    content: " ";
    position: absolute;
    right: 50%;
    top: -13px;
    border-top: none;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: 12px solid rgba(209,209, 209, 0.64);
    border-radius: 10px;
  }
  
  .checkbox-dropdown .ant-checkbox-group {
    -webkit-box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
-moz-box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
    position: absolute;
    top:58px;
    right: -46%;
    padding: 15px;
    z-index: 9;
    background: #fff;
    max-width:100%;

  }
  .checkbox-dropdown .row > div {
    margin: 5px 0px;
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #8e1457;
    border-color: #8e1457;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner
  {
    border-color:#8e1457
  }
  .ant-checkbox-checked::after
  {
    
    border: 1px solid #8e1457;
  }
  .fliter-ctn .row > label{display: inline;width:auto; text-align: right;padding-right: 15px;}
  .fliter-ctn select{  
    border-color: #767676; 
    display: inline;
    width: auto;
    max-width: 80%;
    min-width: 60%;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 14px;
    border-radius: 8px;
    height: 32px;
    margin-top: 5px;

  }
    .showtop label {display: inline; text-align: right; padding-right: 15px; 
      padding-top: calc(0.375rem + 1px);
      padding-bottom: calc(0.375rem + 1px);
    width: auto;}
    .showtop select{width: auto;
    display: inline;
  
    border-color: #767676; 
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 14px;
    border-radius: 8px;
    height: 32px;
    margin-top: 5px;}
    .calendar-picker {

    }
    .main-ctn {width:100%; padding: 10px 0px;}
    
    .table-title {
      color: #8E1457;
      text-align: left;
      font-size:20px;
      padding-bottom: 20px;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
    background-color: #f1f1f1;
  }
  
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:hover, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab:hover{
    background-color: #8e1457;
  }
  
  .ant-tabs-tab:hover .ant-tabs-tab-btn{ color: #ffffff;}
  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active
  {
    color: #707070;
  }
  .ant-tabs-card  .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card  div  .ant-tabs-nav .ant-tabs-tab-active
  {
    
    color: #fff;
    background: #8e1457;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{ color: #ffffff;}

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab
  {
    
    padding: 5px 29px;
    border-bottom: 1px !important;
  }
  .chart-format{
    border: 1px solid #707070;
  }
  .home-chart-ctn >  div{box-shadow: 0px 0px 0px 0px #00000011; border-radius: 0px; border:1px solid #B7B7B7}
  .chart-format.mt-10
  {margin-top:30px}
  .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{
    border:0px !important
  }
  .tab-view .container-fluid{padding-left:0px; padding-right: 0px;}
  
  .tab-view .flex-ctn{ margin-top: 0px;}
  .accountdetails-view {padding-top:20px ;}
  .tab-view{margin-top: 20px;}
.form-label-ctn label {display: inline;}
.form-label-ctn .ant-select {   display: inline-block;
  border-radius: 8px;
  border: 1px solid #767676;
min-width: 150px;
width: auto;
margin-left: 10px;}
.value-ctn {margin-top:9px}
.form-label-ctn{text-align: left;
  margin-bottom: 15px;
  display: inline-block; margin-right: 20px;}
  .form-label-ctn .serch-ctn {max-width:170px; margin-top: 0px;}
  .form-label-ctn .serch-ctn input {margin-top: 0px;}
  .account-info-ctn h2{ font-size: 18px; color: #000000;
    background-repeat: no-repeat;
    background-size: 86px 38px;
    padding: 13px 0px 14px 60px;
    background-position: -17px 0px;
     background-image: url(../../assets/img/heading-icon.svg); font-weight: 700;}
  .account-info-ctn h2 span{color: #8e1457;}
  .value-ctn label{color: #8e1457; font-size: 18px; font-weight: normal; text-transform: capitalize;}
  .value-ctn .value-label{color: #000000;}
  .last-form  {margin-right: 0px;}

  /*new calender changes start here */
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector{border-color:#767676}
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{border-radius: 8px;}
  .trending-home .selContainer > div{
    border-color: #767676;   
    border-radius: 8px;
  }
  .ant-picker
  {
    padding: 7px 9px 7px;    
    border-radius: 8px;
    border: 1px solid #767676;
  }
  .ant-picker-input > input{
    padding-left: 35px;
    font-weight: 700;
    color:#221F1F;
  }
  .ant-picker-suffix
  {
    position: absolute;
    left: 0px;
  }
  .ant-picker .anticon{
    
    vertical-align: 0px;
    color: #707070;
    font-size: 15px;
  }
  .ant-table-cell img{margin-right:6px; width:20px !important;}

  .date-ctn-div {
    display: inline-block;
    margin-right: 10px;
    width:46%;

  }
  .date-ctn-div .title{ font-size: 12px;}
  .trending-home .filterContainer  .rightFilterContainer {padding-top:26px;}
  .date-ctn-div .title {
    margin-bottom: 5px;
    padding-left: 9px;
    
  }
  .date-ctn-div .ant-space-align-center { align-items : start; vertical-align: top;}
  /*new calender changes end here */
  @media (min-width: 992px) and (max-width: 1024px) {
    .ant-table-cell img{width: 15px !important;}
    .fliter-ctn select {
      min-width: 78%;
    }
    .checkbox-dropdown .ant-checkbox-group {
      top:78px;
    }
.fliter-ctn .row > label, .showtop label{ flex: none; text-align: left; display: block;
width: 100%;
padding-top: 0px;
padding-bottom: 4px;
padding-left: 0px; margin-bottom: 0px;}
.showtop label{padding-bottom: 4px;}
.fliter-ctn, .showtop{margin-top: 0px;}

  }
  @media (min-width: 767px) and (max-width: 1124px){
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td
    {padding:10px 5px;}
    
    .ant-table-cell img{width: 15px !important;}
  }