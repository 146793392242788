body {
    margin: 0px;
  }

  #appContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 36px 0px 0px 5vw;
  }
  

  
  h1 {
    margin-bottom: 25px;
  }
