@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: "HelveticaNeueRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#tooltip {
  position: absolute;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 5px #1a1a1a66;
}

.counties {
  stroke: none;
  stroke-width: 0px;
}

#states {
  fill: #ffffff;
  opacity: 0;
}

#states .active {
  display: none;
}

#state-borders {
  fill: none;
  stroke: #fff;
  stroke-width: 1.5px;
  stroke-linejoin: round;
  stroke-linecap: round;
  pointer-events: none;
}

.county-boundary {
  fill: #ffffff;
  stroke: #fff;
  stroke-width: 0.5px;
}

.disparitiesCss rect {
  background-color: #ffffff !important;
}

.disparitiesCss svg {
  background-color: #ffffff !important;
}

.d3-us-map-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 2px;
}

/* Creates a small triangle extender for the tooltip */
.d3-us-map-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "\25BC";
  position: absolute;
  text-align: center;
}

/* Style northward tooltips differently */
.d3-us-map-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}

.legendLinear text {
  font-size: 12px;
}

.disparitiesContainer {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}

#disparity-map-tooltip {
  position: absolute;
  background-color: #f1f1f1;
  padding: 10px;
}



.sunburst-legend-title {
  padding: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
}

.sunburst-legend-title:hover {
  background-color: #f1f1f1;
}

.sunburst-legend-list {
  padding: 5px 5px 0px 30px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
}

.sunburst-legend-list:hover {
  background-color: #f1f1f1;
}

.sunburst-legend-total {
  margin-left: 10px;
  font-family: "HelveticaNeue-Medium" !important;
  width: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.sunburst-legend-total-label {
  padding-left: 5px;
  font-weight: bold;
  font-family: "HelveticaNeue-Medium";
}

.sunburst-legend-total-label1 {
  padding-left: 5px;
  width: 130px;
  font-weight: bold;
  display: flex;
  text-align: right;
  font-family: "HelveticaNeue-Medium";
}

.sunburst-legend-total-percent {
  padding-left: 5px;
  width: 50px;
  font-weight: normal;
  text-align: right;
  font-family: "HelveticaNeue-Light";
}

.sunburst-legend-total-count {
  flex: 1;
  display: inline-block;
  text-align: right;
  font-family: "HelveticaNeue-Medium";
}

.sunburst-legend-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.wheel-legend-title {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 5px 10px;
  height: auto;
}

.wheel-legend-title-1 {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 5px 10px;
  font-size: 14px;
  height: auto;
}


.wheel-progress {
  width: 100px !important;
  background-color: #eeeeee;
  margin-right: 10px;
  margin-left: 5px;
}

.wheel-progress-bar {
  width: 5%;
  min-width: 5%;
  height: 5px;
  background-color: green;
}

.ant-menu>.ant-menu-item:hover,
.ant-menu>.ant-menu-submenu:hover,
.ant-menu>.ant-menu-item:focus,
.ant-menu>.ant-menu-submenu:focus,
.ant-menu>.ant-menu-item-active,
.ant-menu>.ant-menu-submenu-active,
.ant-menu>.ant-menu-item-open,
.ant-menu>.ant-menu-submenu-open,
.ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected {
  color: #D0016F !important;
  border-bottom: 0px solid #D0016F !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 2px solid #D0016F !important;
  color: #D0016F !important;
}

.ant-menu-submenu-title:focus,
.ant-menu-submenu-title:hover {
  color: #D0016F !important;
}



.ant-btn-primary {
  color: #fff;
  border-color: #962163;
  background: #962163;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary:hover {
  color: #fff;
  border-color: #962163;
  background: #962163;
}

.ant-btn-outline {
  color: #000000;
  height: 40px;
}

.ant-btn-default {
  color: #000000;
}

.ant-btn-outline:hover,
.ant-btn-outline:focus,
.ant-btn-default:hover,
.ant-btn-default:focus {
  border-color: #962163;
  color: #962163;
}

.dx-g-bs4-toolbar {
  justify-content: flex-end;

}

.dx-g-bs4-table thead {}

.dx-g-bs4-table th {
  background-color: #962163 !important;
  color: #ffffff !important;
  font-size: 12px;
  height: fit-content;
  white-space: normal !important;
  vertical-align: middle;
  font-weight: 500;

}

#physician-table .dx-g-bs4-table th:nth-child(2),
#physician-table .dx-g-bs4-table th:nth-child(1),
#physician-table .dx-g-bs4-table th:nth-child(1) .text-primary,
#physician-table .dx-g-bs4-table th:nth-child(2) .text-primary {
  background-color: #FAF9F9 !important;
  color: #454545 !important;
}

.dx-g-bs4-table th td {
  text-align: left !important;
}

.dx-g-bs4-table tr td {
  align-items: center;
  font-size: 14px;
  justify-content: center;
  text-align: center;
  flex-direction: row;
}

.dx-g-bs4-table .text-primary {
  color: #ffffff !important;
}

.ant-segmented-item-label {
  font-size: 20px;
  line-height: 20px;
}

.ant-segmented-item-selected:hover {
  color: #ffffff;
}

.ant-segmented-item-selected {
  background-color: #962163;
  color: #ffffff;
  font-size: 20px;
}

.rmdp-wrapper {
  padding-bottom: 40px;
}

.rc-dyn-tabs-tablist>.rc-dyn-tabs-tab.rc-dyn-tabs-selected>.rc-dyn-tabs-title {
  border-color: #962163 !important;
}

.rc-dyn-tabs-tablist {
  z-index: 1000;
}
.rc-dyn-tabs-title{
  font-size: 14px;
  padding: 5px 30px !important;
  min-width: 10px !important;
}

.ant-modal-centered {}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #962163 !important;
  border-color: #962163 !important;
}

.ant-modal {
  max-width: 100vw !important;
}

.ant-tabs-tab-active {
  border-bottom: 3px solid #962163 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
}

.ant-tabs-tab-btn {
  color: #3e3e3e;
}

.ant-tabs-tab {
  border-bottom: 3px solid #ffffff00;
}

.popover {
  height: 300px !important;
  overflow: hidden;
  overflow-y: scroll;
  left: 80% !important;
  top: 50px !important;
  transform:none !important;
}
